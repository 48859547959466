<template>
 <div id="cooperation" class="cooperation" v-title data-title="生态合作">
  <!-- 页面导航 -->
  <menu-components type="cooperation"/>

  <!-- 页面主体 -->
  <main class="main">
   <!--banner-->
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}">
       <div class="container">
        <h2>{{item.txt}}</h2>
        <p style="margin-top:.2rem;margin-bottom:.5rem">{{item.txt4}}</p>
        <el-button @click="jumpUrl" class="other-p-bt">我要合作&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
       </div>
      </div>
     </el-carousel-item>
    </el-carousel>
   </section>

   <!-- 俱乐部介绍 -->
   <section class="cooperation-1">
    <div class="container">
     <el-row :gutter="150"  class="plan-1-b" style="margin-top:.67rem">
      <el-col :sm="24" :md="12">
       <div class="scrollflow -slide-right plan-1-b-l plan-title">
        <template>
         <div>{{fuse1Details.title}}</div>
         <h2>{{fuse1Details.title1}}</h2>
        </template>
        <p>{{fuse1Details.txt}}</p>
        <p>{{fuse1Details.txt1}}</p>
        <el-button @click="jumpUrl">我要合作&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
       </div>
      </el-col>
      <el-col :sm="24" :md="12">
       <div class="plan-1-b-r-d scrollflow -slide-left " v-if="fuse1Details.img">
        <!--<div class="flex serve_list_box justify-between">-->
        <!-- <div v-for="(item, index) in serveList" style="border:none;width: 33%" :key="index">-->
        <!--  <div class="flex justify-center">-->
        <!--   <h2>-->
        <!--    <animate-number-->
        <!--        class="scrollflow cph-font"-->
        <!--        from="1"-->
        <!--        :to="item.num"-->
        <!--        duration="2000"-->
        <!--        easing="easeOutQuad"-->
        <!--        :formatter="formatter"-->
        <!--    ></animate-number>-->
        <!--   </h2>-->
        <!--   <span>{{item.st}}</span>-->
        <!--  </div>-->
        <!--  <p>{{item.name}}</p>-->
        <!-- </div>-->
        <!--</div>-->
        <img style="height: 1.96rem" :src="fuse1Details.img" alt="">
       </div>
      </el-col>
     </el-row>
    </div>
   </section>

   <!-- 合作方向 -->
   <section class="cooperation-2">
    <div class="container ">
     <div class="plan-1-b-l plan-title scrollflow -slide-top">
      <template>
       <div>{{fuse2Details.title}}</div>
       <h2>{{fuse2Details.title1}}</h2>
      </template>
     </div>
    </div>

    <ul class="flex align-center justify-between scrollflow -slide-top" >
     <li class="flex align-center justify-center" v-for="(item, index) in fuse2List"
         :style="{backgroundImage: 'url('+ item.img + ')'}"
         :key="index">
      <div>
       <h2>{{item.title}}</h2>
       <p>{{item.txt}}</p>
      </div>
     </li>
    </ul>
   </section>

  <!-- 优秀的合作伙伴-->
   <section class="cooperation-3">
    <div class="container scrollflow -slide-top">
     <div class="plan-1-b-l plan-title">
      <template>
       <div>{{fuse3Details.title}}</div>
       <h2>{{fuse3Details.title1}}</h2>
      </template>
     </div>
     <img src="@/assets/images/bg7.jpg" alt="">
    </div>
   </section>


  </main>

  <!-- 页面底部 -->
  <footer-components/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import Public from "@/assets/js/public";
export default {
  name: 'Cooperation',
  components: {
   MenuComponents,
   FooterComponents
  },
  mixins: [],
  data () {
    return {
     // 轮播列表
     carouselList: [
        { img: require('@/assets/images/23.jpg'), txt: '生态合作', txt4: '为在校生提供就业培训、各类活动等服务', jumpUrl: ''},
     ],
     // 当前轮播下标
     initialIndex: 0,
     // 当前方案概述详情
     fuse1Details: {
      title: 'Daxuezhanng Introduce',
      title1: '大学掌介绍',
      txt: '大学掌是面向大学生，提供一站式的校园服务解决方案。它旨在解决大学生在校园生活中可能遇到的各种问题，包括但不限于上网认证、校园卡办理、WiFi宽带办理等。同时，大学掌也致力为大学生提供学习，就业，校园生活等服务，以满足大学生多元化的需求。',
      img: require('@/assets/images/17.jpg')
     },
     serveList: [
      {num: 35, st: '万+', name: '公众号用户'},
      {num: 8, st: '万+', name: '小程序用户'},
      {num: 420, st: '万+', name: 'portal页日访问量'},
     ],
     fuse2Details: {
      title: 'Cooperation Direction',
      title1: '合作方向',
     },
     fuse2List: [
      {img: require('@/assets/images/20.jpg'), title: '就业实习', txt:'Employment'},
      {img: require('@/assets/images/18.jpg'), title: '职前实训', txt:'Vocational Training'},
      {img: require('@/assets/images/21.jpg'), title: '文化研学', txt:'Cultural Studies'},
      // {img: require('@/assets/images/19.jpg'), title: '商业文娱', txt:'Business and entertainment'},
     ],
     fuse3Details: {
      title: 'Excellent partner',
      title1: '优秀合作伙伴',
     },
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
   /* 轮播切换 */
   changeCarousel (val) {
    this.initialIndex = val;
    this.$refs.carousel.setActiveItem(val)
   },
   /* 切换上一个 */
   leftPrev () {
    this.$refs.carousel.prev(this.initialIndex)
   },
   /* 切换下一个 */
   rightNext () {
    this.$refs.carousel.next(this.initialIndex)
   },
   // 鼠标进入 进出
   handleMouse(index) {
    this.homeS3Index = index
   },
   formatter: function (num) {
    return Public.formatterNum(num)
   },
   /* 我要合作 */
   jumpUrl () {
    window.open('https://dataex.autewifi.com/#/collect/6dadd600ea644d49babc9774bf6c4e2f')
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
